import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

interface KeyFeaturesProps {
  className?: string;
  productRef?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#e8e6e6',
    paddingTop: 50,
    paddingBottom: 50
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: 20 , fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  image: {
    // width: 300,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    }
  },
  image2: {
    width: 450,
    alignSelf: 'center',
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: { width: 300 },
    [theme.breakpoints.down('sm')]: { width: 200 }
  },
  textContent: {
    flex:1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center', 
    margin: 30
  }
}));

const features = [
  {
    title:  'Spotlight',
    content: `Spotlight allows you to see your debt standing and future savings at a glance. This helps you to be informed of your debt status and what's ahead without the need of pulling a report.`,
    location: 'California',
    image: 'Spotlight.png',
    width: 340
  },
  {
    title:  'Missed Transactions',
    content: `Don’t worry about missing recording your income and expenses. There’s a special place for you to manage them in a breeze.`,
    location: 'Boston',
    image: 'Missed_Transactions.png',
    width: 400
  },
  {
    title:  'Insights & Reports',
    content: `View your financial stats without the hassle of generating a report`,
    location: 'Maryland',
    image: 'Insights_ Analytics.png',
    width: 400
  }
]

const KeyFeatures: FC<KeyFeaturesProps> = ({ className, productRef, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      ref={productRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Box>
          <Box flex={1} display={'flex'} flexDirection='row' justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                style={{ fontWeight: 'bold', color: '#6e6e6e', marginBottom: 20 }}
                >    
                 What you get is more than what you see.
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display={'flex'} flexDirection='row' justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="body1"
                align="center"
                color="textSecondary"
                style={{ color: '#6e6e6e' }}
                >    
                 Ansur's benefits exceeds its features. 
              </Typography>     
            </Box>
          </Box>
          <Fade right> 
            <Grid container style={{ marginTop: 50, marginBottom: 20 }}>
              <Grid md={6} style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
                <Box>
                    <img
                      className={clsx(classes.image2)}
                      src="/static/home/manage_liabilities.png"
                    />
                </Box>
              </Grid>
              <Grid md={6} className={classes.textContent}>
                <Box >
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="left"
                  >
                    Manage your liabilities in one platform.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300,  color: '#6e6e6e' }}
                  >
                    With Ansur your finances are organized in a way that makes understanding them significantly easier. With a macro view and the ability to see where your money is going your liabilities have never been easier to manage. 
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Fade>
          <Fade left> 
            <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid md={6} className={classes.textContent}>
                <Box>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="left"
                  >
                    Keep track of your payment schedule.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300, color: '#6e6e6e' }}
                  >
                    With customizable notifications your payments have never been easier to track. While the notifications mean that you will be aware of every payment our customized reports allow you to go back into your financial history and track exactly how you achieved your financial freedom. 
                  </Typography>
                </Box>
              </Grid>
              <Grid md={6} style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
                <Box display={'flex'}>
                  <img
                    className={clsx(classes.image2)}
                    // width={600}
                    src="/static/home/keep_track_of_payment_schedule.png"
                  />
                </Box>
              </Grid>
            </Grid>
          </Fade>
          <Fade right>
            <Grid container style={{ marginTop: 100, marginBottom: 50 }}>
              <Grid md={6} style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
                <Box display={'flex'}>
                  <img
                    className={clsx(classes.image2)}
                    src="/static/home/Shorten_through_smart_payments.png"
                  />
                </Box>
              </Grid>
              <Grid md={6} className={classes.textContent}>
                <Box >
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="left"
                  >
                    Shorten Loan Periods with Smart Payments
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins',fontWeight: 300, color: '#6e6e6e' }}
                  >
                    With our smart payment system our community has saved hundreds of thousands of dollars in interest alongside paying off their mortgage and consumer loans in significantly less time than their previous paths had planned for them. Smart payments are your ticket to financial freedom and getting on track to the goals in life that matter most to you.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Fade>
        </Box>
        <Box marginTop={10}>
            <Box flex={1} display={'flex'}  justifyContent="center" alignContent='center' alignItems={'center'}>
              <Box>
                <Typography
                  variant="h1"
                  align="center"
                  color="textSecondary"
                  style={{ fontWeight: 'bold', color: '#6e6e6e', marginBottom: 40 }}
                  >    
                  Other Key Features
                </Typography>
              </Box>
            </Box>
            <Grid container>
              {features.map(story => (
                <Grid md={4} style={{ padding: 20, flex: 1 }}>
                  <Zoom>
                  {/* <Grid> */}
                    <Box justifyContent={'center'} display={'flex'} flex={1}>
                      <img
                        className={clsx(classes.image)}
                        width={smallSize ? story.width - 100 : story.width }
                        src={`/static/home/${story.image}`}
                      />
                    </Box>
                  {/* </Grid>
                  <Grid> */}
                    <Typography
                      className={clsx(classes.title)}
                      variant="h1"
                      align="center"
                    >
                      {story.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      color="textSecondary"
                      style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300, color: '#6e6e6e' }}
                    >
                      {story.content}
                    </Typography>
                  {/* </Grid> */}
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          </Box>
      </Container>
    </div>
  );
};

KeyFeatures.propTypes = {
  className: PropTypes.string
};

export default KeyFeatures;

import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  colors,
  IconButton,
  Divider,
  Link,
  Grid
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import type { Theme } from 'src/theme';
import Fade from 'react-reveal/Fade';
interface InstructionsProps {
  className?: string;
  faqRef?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: colors.common.white,
    // paddingTop: 20,
    // paddingBottom: 20
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  main: {
    flex:2,
    display:'flex',
    flexDirection:'column',
    margin: 50,
    [theme.breakpoints.down('sm')]: { 
      margin: 10
    }
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: 0 , fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  image: {
    width: 600,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  content: {
    color: '#6e6e6e',
    marginTop: 1 , fontFamily: 'Poppins',
  },
  box: {
    marginBottom: 20
  },
  devider: {
    marginTop: 20,
    marginBottom: 20,
  },
  link: {
    fontFamily: 'Poppins',
    marginBottom: 10,
    textDecoration: 'underline'
  }
}));

const Faq: FC<InstructionsProps> = ({ className, faqRef, ...rest }) => {
  const classes = useStyles();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)

  return (
    <div
      ref={faqRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Fade>
        <Box display='flex' flexDirection='row' marginTop={10} marginBottom={10}> 
          <Grid container>
            <Grid md={6}>
            <Box className={classes.main}>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    How does Ansur accelerate my mortgage payment?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(!show1)
                        setShow2(false)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show1 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show1 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Ansur looks at your financial position as a whole and calculates the fastest path to paying off any debt you may have. It does this through our smart payment system and through our live map that is updated in real time according to your specifications. 
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Are there requirements to use Ansur?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(!show2)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show2 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show2 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Yes there are. First, you must make more than you spend each month. Second, you will need a primary account that will be used for all incoming and outgoing funds. Third, you will need at least one months income in your primary account in order for Ansur to properly calculate your map.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Can I also accelerate my credit card debt or other loans?
                  </Typography>
                  <Box>
                   <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(false)
                        setShow3(!show3)
                      }} 
                      size='small'
                    >
                      { show3 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show3 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Yes you can! Ansur takes all of your incomes and debts and creates a custom financial map  for you to easily follow. This plan looks at your financial position from a holistic view and will recommend smart payments that cater to you achieving financial freedom in the shortest period of time possible.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
            </Box>
            </Grid>
            <Grid md={6}>
              <Box justifyContent={'center'}>
                <img
                  className={clsx(classes.image)}
                  src="/static/home/input financials.png"
                />
              </Box>
            </Grid>
          </Grid>
            {/* <Box flex={2} display='flex' flexDirection={'column'} margin={10}>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    How does Ansur accelerate my mortgage payment?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(!show1)
                        setShow2(false)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show1 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show1 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Ansur looks at your financial position as a whole and calculates the fastest path to paying off any debt you may have. It does this through our smart payment system and through our live map that is updated in real time according to your specifications. 
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Are there requirements to use Ansur?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(!show2)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show2 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show2 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Yes there are. First, you must make more than you spend each month. Second, you will need a primary account that will be used for all incoming and outgoing funds. Third, you will need at least one months income in your primary account in order for Ansur to properly calculate your map.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Can I also accelerate my credit card debt or other loans?
                  </Typography>
                  <Box>
                   <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(false)
                        setShow3(!show3)
                      }} 
                      size='small'
                    >
                      { show3 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show3 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Yes you can! Ansur takes all of your incomes and debts and creates a custom financial map  for you to easily follow. This plan looks at your financial position from a holistic view and will recommend smart payments that cater to you achieving financial freedom in the shortest period of time possible.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
            </Box>
            <Box flex={3} margin={5}>
              <Box justifyContent={'center'}>
                <img
                  className={clsx(classes.image)}
                  src="/static/home/input financials.png"
                />
              </Box>
            </Box> */}
          </Box>
        </Fade>
      </Container>
    </div>
  );
};

Faq.propTypes = {
  className: PropTypes.string
};

export default Faq;
